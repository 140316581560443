<template>
  <Form
    v-if="!hasBeenSubmitted"
    class="relative pt-14 pb-5"
    @submit="handleSubmit"
  >
    <h1 class="font-bold text-center text-3xl">Request an Invite</h1>
    <p class="text-center font-semibold">
      Please complete the form below to request an invite.
    </p>
    <p class="text-center font-semibold">
      Once approved, you’ll receive a welcome email.
    </p>

    <TextInput
      v-model="formData.fullName"
      label="Full Name"
      placeholder="Full Name"
      :rules="[isRequired, nameMaxLength]"
      @update:model-value="errorMessage = ''"
    />
    <TextInput
      v-model="formData.preferredName"
      label="Preferred Name (First Name or Nickname)"
      placeholder="Preferred Name"
      :rules="[isRequired, nameMaxLength]"
      @update:model-value="errorMessage = ''"
    />
    <TextInput
      v-model="formData.email"
      label="Email"
      placeholder="Email"
      disabled
    />
    <TextInput
      v-model="formData.company"
      label="Company"
      placeholder="Company"
      :rules="[companyMaxLength]"
      @update:model-value="errorMessage = ''"
    />

    <alert v-if="errorMessage" class="mt-4" :type="AlertType.ERROR">
      {{ errorMessage }}
    </alert>

    <Btn
      :size="BtnSize.LG"
      :variant="BtnVariant.FILLED"
      :color="BtnColor.PRIMARY"
      class="w-full mt-10"
      type="submit"
    >
      Request an invite
    </Btn>
  </Form>
  <div v-else class="pt-14 pb-13">
    <h1 class="font-bold text-center text-3xl">
      Thank you, your request has been received!
    </h1>
    <p class="text-center font-semibold">
      We will review your request and then once approved you’ll receive a
      welcome email to continue creating your account.
    </p>
  </div>
  <Btn
    :size="BtnSize.LG"
    :color="hasBeenSubmitted ? BtnColor.PRIMARY : BtnColor.SECONDARY"
    class="w-full mb-14"
    @click="emit('change-type', SelfRegisterType.SIGN_IN)"
  >
    Back to Sign in
  </Btn>
</template>

<script setup lang="ts">
import { ref } from '#imports';
import { Form } from 'vee-validate';
import { TextInput } from '~/components/inputs/text-input';
import { Btn, BtnColor, BtnSize, BtnVariant } from '~/components/btn';
import { isRequired, maxLength } from '~/utils/validators';
import { useLoader } from '~/composables/use-loader';
import { SelfRegisterType } from '~/api/auth';
import { apiRequestInvitation, RequestInvitationDto } from '~/api/accounts';
import { Alert, AlertType } from '~/components/alert';

type Props = {
  modelValue: string;
};
const props = defineProps<Props>();

const emit = defineEmits<{
  (e: 'change-type', value: SelfRegisterType): void;
}>();

const errorMessage = ref('');

const formData = ref<RequestInvitationDto>({
  fullName: '',
  preferredName: '',
  company: '',
  email: props.modelValue,
});

const hasBeenSubmitted = ref<boolean>(false);

const nameMaxLength = maxLength(200);
const companyMaxLength = maxLength(100);

const handleSubmit = async () => {
  await useLoader({
    action: async () => {
      await apiRequestInvitation({
        ...formData.value,
        company: formData.value.company || null,
      });
      hasBeenSubmitted.value = true;
    },
    onError: (err: any) => {
      errorMessage.value = err?.data?.message ?? 'Auth failed';
    },
  });
};
</script>
